let rampSet = false

export const isRampSet = () => {
  if (rampSet) return Promise.resolve()

  return new Promise(waitForRamp)

  function waitForRamp(resolve, reject) {
    if (window.ramp && window.ramp.que) {
      rampSet = true
      resolve()
    } else setTimeout(waitForRamp.bind(this, resolve, reject), 30)
  }
}
