import React, { lazy } from 'react'
// import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './configs'
import DetectDeviceProvider from 'wsc/components/context/DetectDeviceProvider'
// import { PreviewSiteBannerProvider } from 'wsc/components/context/PreviewSiteBannerProvider'
// import { PostNavProvider } from 'wsc/components/context/PostNavProvider'
import { NavbarProvider } from 'wsc/components/context/NavbarProvider'
import OnetrustProvider from 'wsc/components/context/OnetrustProvider'

import routes from './configs/routes'
// import client from './utils/initApollo'
import GlobalStyle from './components/styled/GlobalStyle'
import ScrollToTop from './components/route/ScrollToTop'
import HistoryChangeHandler from './components/HistoryChangeHandler'
import ReactComponentLoader from 'wsc/components/ReactComponentLoader'

// const Home = lazy(() => import('./pages/Home'))
// const Post = lazy(() => import('./pages/Post'))
// const Tag = lazy(() => import('./pages/Tag'))
// const AuthorIndex = lazy(() => import('./pages/AuthorIndex'))
// const Category = lazy(() => import('./pages/Category'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Error = lazy(() => import('./pages/Error'))
// const Author = lazy(() => import('./pages/Author'))
// const Series = lazy(() => import('./pages/Series'))
// const About = lazy(() => import('./pages/About'))
// const Newsletter = lazy(() => import('./pages/Newsletter'))
// const ContentIndex = lazy(() => import('./pages/ContentIndex'))
// const Contact = lazy(() => import('./pages/Contact'))
// const Webinar = lazy(() => import('./pages/Webinar'))
// const Submissions = lazy(() => import('./pages/Submissions'))
// const Hub = lazy(() => import('./pages/Hub'))
const BabyName = lazy(() => import('./pages/BabyName'))
const BabyNameGlobalList = lazy(() => import('./pages/BabyNameGlobalList'))
const BabyNameIndividual = lazy(() => import('./pages/BabyNameIndividual'))
const BabyNameCountryList = lazy(() => import('./pages/BabyNameCountryList'))
const BabyNameAlphabetList = lazy(() => import('./pages/BabyNameAlphabetList'))
const BabyNameSearch = lazy(() => import('./pages/BabyNameSearch'))

const App = () => (
  <OnetrustProvider>
    {/* <ApolloProvider client={client}> */}
    <DetectDeviceProvider>
      {/* <PreviewSiteBannerProvider> */}
      {/* <PostNavProvider> */}
      <NavbarProvider>
        <GlobalStyle />
        <BrowserRouter>
          <HistoryChangeHandler />
          <ScrollToTop />
          <ReactComponentLoader viewportLazyLoad={false}>
            <Switch>
              {/* Static Routes */}
              {/* <Route exact path={routes.contact.path} component={Contact} /> */}
              {/* <Route exact path={routes.homepage.path} component={Home} /> */}
              {/* <Route exact path={routes.about.path} component={About} /> */}
              {/* <Route exact path={routes.newsletter.path} component={Newsletter} /> */}
              <Route exact path={routes.babyName.path} component={BabyName} />
              <Route exact path={routes.babyNameGlobalList.path} component={BabyNameGlobalList} />
              <Route exact path={routes.error.path} component={Error} />
              {/* Pattern: /static/dynamic  */}
              {/* <Route exact path={routes.author.path} component={Author} /> */}
              {/* <Route exact path={routes.authorIndex.path} component={AuthorIndex} /> */}
              {/* <Route exact path={routes.tag.path} component={Tag} /> */}
              {/* <Route exact path={routes.contentIndex.path} component={ContentIndex} /> */}
              {/* <Route exact path={routes.series.path} component={Series} /> */}
              {/* <Route exact path={routes.webinar.path} component={Webinar} /> */}
              {/* <Route exact path={routes.submissions.path} component={Submissions} /> */}
              {/* <Route exact path={routes.hub.path} component={Hub} /> */}
              <Route exact path={routes.babyNameCountryList.path} component={BabyNameCountryList} />
              <Route
                exact
                path={routes.babyNameAlphabetList.path}
                component={BabyNameAlphabetList}
              />
              <Route exact path={routes.babyNameIndividual.path} component={BabyNameIndividual} />
              <Route exact path={routes.babyNameSearch.path} component={BabyNameSearch} />
              {/* Pattern: /dynamic/dynamic/dynamic */}
              {/* <Route exact path={routes.post.path} component={Post} /> */}
              {/* Pattern: /dynamic */}
              {/* <Route exact path={routes.category.path} component={Category} /> */}
              {/* Fallback */}
              <Route component={NotFound} />
            </Switch>
          </ReactComponentLoader>
        </BrowserRouter>
      </NavbarProvider>
      {/* </PostNavProvider> */}
      {/* </PreviewSiteBannerProvider> */}
    </DetectDeviceProvider>
    {/* </ApolloProvider> */}
  </OnetrustProvider>
)

export default App
