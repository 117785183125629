import { useState, useEffect } from 'react'

/**
 * Detect Onetrust Active Group
 * @returns {{ isC0001Active: boolean, isC0002Active: boolean, isC0003Active: boolean, isC0004Active: boolean }}
 */

export const useDetectActiveGroup = () => {
  const [activeGroup, setActiveGroup] = useState({
    isC0001Active: window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0001'),
    isC0002Active: window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0002'),
    isC0003Active: window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0003'),
    isC0004Active: window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0004'),
  })
  useEffect(() => {
    const handleGroupUpdate = event => {
      setActiveGroup({
        isC0001Active: event.detail.includes('C0001'),
        isC0002Active: event.detail.includes('C0002'),
        isC0003Active: event.detail.includes('C0003'),
        isC0004Active: event.detail.includes('C0004'),
      })
    }
    window.addEventListener('OneTrustGroupsUpdated', handleGroupUpdate)

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handleGroupUpdate)
    }
  }, [activeGroup])

  return activeGroup
}
