import React, { useContext } from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import styled, { css, createGlobalStyle } from 'styled-components/macro'

import AdCallout from '../AdCallout'
import { OnetrustStateContext } from '../context/OnetrustProvider'
import { getUnitId } from '../../utils/adUnits'
import isPrerenderBot from '../../utils/browser'
import { MEDIA } from '../../utils/styles'
import RawAdSlot from './RawAdSlot'

export const ScAdSlot = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
  ${props =>
    props.disabled &&
    css`
      margin: 0 !important;
      padding: 0 !important;
    `}
`
export const ScAdSlotLeader = styled(ScAdSlot)`
  ${props =>
    props.bgColorForMobile &&
    css`
      ${MEDIA.MOBILE`background-color: ${props.bgColorForMobile}`};
    `}
  ${props =>
    props.bgColorForTablet &&
    css`
      ${MEDIA.TABLET`background-color: ${props.bgColorForTablet}`};
    `}
  ${props =>
    props.bgColorForDesktop &&
    css`
      ${MEDIA.DESKTOP`background-color: ${props.bgColorForDesktop}`};
    `}
  .takeover-active & {
    background-color: unset;
  }
  ${props =>
    props.adCalloutColor &&
    css`
      --adCalloutColor: ${props.adCalloutColor};
    `}
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  min-height: calc(100px + 20px);
  margin: 40px -50vw;
  ${MEDIA.DESKTOP`
    margin: 50px -50vw;
  `};
  overflow: visible;
`
export const ScAdSlotRightRail = styled(ScAdSlot)`
  margin-bottom: 50px;
  min-height: calc(250px + 20px);
`

const AdSlot = props => {
  const {
    type,
    lazy,
    lazyLoadOffset,
    lazyLoadPlaceholder,
    forwardedRef,
    tagless,
    includeCalloutStyling,
    placeholder,
  } = props

  const { isC0004Active } = useContext(OnetrustStateContext)
  if (isPrerenderBot() || !isC0004Active) return <></>

  const unit = {
    type: type,
    selectorId: getUnitId(type),
  }

  let adSlotComponent = <RawAdSlot unit={unit} tagless={tagless} placeholder={placeholder} />

  if (lazy) {
    const adLazyPlaceholder = lazyLoadPlaceholder || (
      <div className={`pw-lazy-${unit.selectorId}`}></div>
    )
    adSlotComponent = (
      <LazyLoad placeholder={adLazyPlaceholder} once offset={lazyLoadOffset}>
        {adSlotComponent}
      </LazyLoad>
    )
  }
  // return includeCalloutStyling ? (
  //   <AdCallout showCallout={showCallout} forwardedRef={forwardedRef}>
  //     {adSlotComponent}
  //   </AdCallout>
  // ) : (
  //   <>{adSlotComponent}</>
  // )

  return (
    <AdCallout showCallout={includeCalloutStyling} forwardedRef={forwardedRef}>
      {adSlotComponent}
    </AdCallout>
  )
}

AdSlot.defaultProps = {
  type: '',
  lazy: true,
  lazyLoadOffset: 1000,
  lazyLoadPlaceholder: null,
  disabled: false,
  callback: null,
  outOfPage: false,
  forwardedRef: null,
  tagless: false,
  includeCalloutStyling: true,
  placeholder: false,
}

AdSlot.propTypes = {
  type: PropTypes.string,
  lazy: PropTypes.bool,
  lazyLoadOffset: PropTypes.number,
  lazyLoadPlaceholder: PropTypes.element,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  outOfPage: PropTypes.bool,
  forwardedRef: PropTypes.shape({ current: PropTypes.any }),
  tagless: PropTypes.bool,
  includeCalloutStyling: PropTypes.bool,
  placeholder: PropTypes.bool,
}

export default AdSlot
