import { css } from 'styled-components/macro'

/**
 * get flex style with sticky position
 */
export const withStickyPosition = css`
  position: sticky;
  align-self: flex-start;
  top: 0;
`
