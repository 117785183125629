/**
 * Loads global configs so WSC can consume them
 */
// import experimentConfig from '../statics/configs/abTest.json'
import { setConfig } from 'wsc/globalConfig'
// import { setExperimentConfig } from 'wildsky-components'
import './app'

// import so we can expose to GlobalConfig
import routes from './routes'
// import category_en from '../statics/configs/category.json'

// setExperimentConfig(experimentConfig)

// set global configs
setConfig('Routes', routes)
// setConfig('Category', { en: category_en })
