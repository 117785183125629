import { css } from 'styled-components/macro'
import { getConfig } from './globalConfig'
import { MEDIA, rgba } from './styleUtils'

const { COLORS } = getConfig('StyleConfig')

const defaultGlobalStyle = css`
  .MuiAppBar-root{
    z-index: 10000 !important;
  }

  .HomeTopFeaturedMainSection {
    --withImageBoxShadow_boxShadowLength: 15px;
    ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 18px;`}
    ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 20px;`}
  }

  .HomeTopFeaturedSubSection {
    --withImageBoxShadow_boxShadowLength: 5px;
    ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 8px;`}
    ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 6px;`}
  }

  .AuthorImage {
    --withImageBoxShadow_boxShadowLength: 10px;
  }

  .AuthorIndexImage {
    --withImageBoxShadow_boxShadowLength: 10px;
  }

  .PostHeader .AuthorImage {
    --withImageBoxShadow_boxShadowLength: 5px;
  }

  .SuggestedPosts {
    --withImageBoxShadow_boxShadowLength: 5px;
  }

  .BrandedContent {
    --withImageBoxShadow_boxShadowLength: 8px;
  }

  [class*="PostList"] {
    
    --inContentAdMarginBottom: 40px;
    ${MEDIA.DESKTOP`--inContentAdMarginBottom: 60px;`}
  }

  [class*="PostPage"] {

    --inContentAdMarginBottom: 2.5rem;
    ${MEDIA.DESKTOP`--inContentAdMarginBottom: 3.125rem`}
  }

  /* -------------------------------------------------------- */
  /* Button styles
  /* -------------------------------------------------------- */

  .withButtonOutlined {
    --withButton_Color: ${COLORS.LIGHT_BLUE};
    --withButton_BackgroundColor: ${COLORS.WHITE};
    --withButton_Border: 1px solid ${COLORS.LIGHT_BLUE};
    --withButton_SvgFill: ${COLORS.LIGHT_BLUE}

    --withButton_HoverBackgroundColor: ${COLORS.LIGHT_BLUE};
    --withButton_HoverBorder: 1px solid ${COLORS.LIGHT_BLUE};

    --withButton_ActiveBackgroundColor: ${COLORS.DARK_BLUE};
    --withButton_ActiveBorder: 1px solid ${COLORS.DARK_BLUE};
  }

  .withButtonLight {
    --withButton_BackgroundColor: transparent;
    --withButton_Border: 1px solid ${COLORS.WHITE};
    
    --withButton_HoverBackgroundColor: ${rgba(COLORS.WHITE, '0.3')};
    
    --withButton_ActiveBackgroundColor: ${rgba(COLORS.WHITE, '0.7')};
  }
`

export default defaultGlobalStyle
