import { setConfig, getConfig } from 'wsc/globalConfig'

const styleConfig = {
  FONT_FAMILIES: {
    SERIF: `'Lora', serif`,
    SANSSERIF: `'Avenir Next LT Pro', sans-serif`,
    JWPLAYER: `'JW Player Font Icons'`,
  },
}

setConfig('StyleConfig', styleConfig)

export default getConfig('StyleConfig')
