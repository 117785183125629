const APP_CONFIGS = {
  // process.env.DEPLOY_PRIME_URL is a built-in environment variable from netlify
  url:
    process.env.BRANCH && process.env.BRANCH !== 'master'
      ? process.env.DEPLOY_PRIME_URL
      : 'https://babynames.mom.com',
  name: 'Mom.com',
  title: 'Pregnancy, Parenting, Lifestyle, Beauty: Tips & Advice',
  logo: '/images/logo/momcom-icon.png',
  description:
    "You're a mom AND a woman. We get it! Find real-world parenting advice, fashion & beauty inspiration and great conversation with moms like you.",
  facebookAppId: '133960820011850',
  // default language
  language: 'en-US',
  envUrl: process.browser ? window.location.protocol + '//' + window.location.host : null,
  // Max number of posts to load via infinite scroll
  maxInfiniteScrollPosts: 3,
  // Number of pixels to look ahead of scroll to preload next article
  infiniteScrollLookahead: 2000,
  prop30SiteName: 'momcom',
  noSkimlinksForTheseCategorySlugs: ['mom-life-shopping', 'mom-life-health-and-wellness'],
  ctaBannerData: {
    imageNames: {
      desktop1x: 'momlife_cta_desktop.jpg',
      desktop2x: 'momlife_cta_2x_desktop.jpg',
      mobile1x: 'momlife_cta_mobile.jpg',
      mobile2x: 'momlife_cta_2x_mobile.jpg',
    },
    altText: 'Talk to moms about mom stuff. And make money doing it.',
    buttonUrl: `https://cafemom.com/momlife?utm_source=Mom.com&utm_medium=site_banner`,
    styles: `
    margin-bottom: 3.125rem
  `,
  },
}

module.exports = APP_CONFIGS
