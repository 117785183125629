export const getFpv = () => {
  let fpv = window.sessionStorage.getItem('fpv') || 0
  return parseInt(fpv)
}
export const incrementFpv = () => {
  window.sessionStorage.setItem('fpv', getFpv() + 1)
}
export const getPv = () => {
  let pv = window.sessionStorage.getItem('pv') || 0
  return parseInt(pv)
}
export const incrementPv = () => {
  window.sessionStorage.setItem('pv', getPv() + 1)
}
