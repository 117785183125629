let units = {}
let targeting = []

export const getUnitId = type => {
  if (typeof units[type] === 'undefined') {
    units[type] = {}
    units[type].count = 1
    return `ad-${type}`
  } else {
    ++units[type].count
    return `ad-${type}${units[type].count}`
  }
}

export const setTargeting = _targeting => {
  targeting = _targeting
}

export const getTargeting = () => targeting

export const clearTargeting = () => {
  targeting = []
}
