import React from 'react'
import PropTypes from 'prop-types'
import { useDetectActiveGroup } from '../../hooks/useDetectActiveGroup'

export const OnetrustStateContext = React.createContext(null)

const OnetrustProvider = props => {
  const { isC0001Active, isC0002Active, isC0003Active, isC0004Active } = useDetectActiveGroup()

  return (
    <OnetrustStateContext.Provider
      value={{ isC0001Active, isC0002Active, isC0003Active, isC0004Active }}
    >
      {props.children}
    </OnetrustStateContext.Provider>
  )
}

OnetrustProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OnetrustProvider
