import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { oneTrustUserConsent } from 'wsc/utils/oneTrustLib'
import { incrementFpv, incrementPv } from 'wsc/utils/fpv'
import { clearAllActivePosts } from 'wildsky-components'
import { clearTargeting } from 'wsc/utils/adUnits'
import { setProp30 } from 'wsc/utils/googleTagManager'
import { isCategoryPageFromPathName } from 'wsc/utils/routeNameFromPath'

// Flag for checking first page is loaded
let isFirstPage = true
let isPreviousPageCategoryPage = null

// The history argument will be added automatically by React Router.
const HistoryChangeHandler = props => {
  const { history, location } = props
  incrementFpv()
  incrementPv()
  setProp30({ path: location.pathname })

  useEffect(() => {
    // check current page is category page or not
    const isCategoryPage = isCategoryPageFromPathName(location.pathname)

    if (isFirstPage) {
      isFirstPage = false

      // Things that need to trigger when consent state is changed
      oneTrustUserConsent.registerListener(() => {
        // do something that need user consent here
      })

      // why we run line of code below on first page only?
      window.embedly && window.embedly('card', 'embedly-card')

      // On initial page load of category page
      if (isCategoryPage) {
        window.isCategoryPage = true
      }
    } else {
      // When navigating from non-category --> category
      if (isPreviousPageCategoryPage === false && isCategoryPage) {
        window.ramp &&
          window.ramp.que.push(() => {
            window.ramp.setPath('category')
          })
      }

      // When navigating from category --> non-category
      if (isPreviousPageCategoryPage === true && !isCategoryPage) {
        window.ramp &&
          window.ramp.que.push(() => {
            window.ramp.setPath('ROS')
          })
      }
    }

    const unlisten = history.listen((location, action) => {
      /* THINGS TO DO IMMEDIATELY AFTER LOCATION CHANGED */

      clearAllActivePosts()

      if (window.ramp && window.ramp.destroyUnits) {
        window.ramp.destroyUnits('all')

        // We found that when use browser navigation (back/forward button) (action='POP')
        // the code in this listener function might not execute first when route change
        //    Sometimes, another component already make a call to adUnits.setTargeting
        //    and we don't want to clear those targeting that just set
        //      NOTE: we only SKIP calling adUnits.clearTargeting but still call PageOS.clearAllTags
        //            RawAdSlot's useEffect (expected to execute after this function)
        //            should be able to read the right targeting from adUnits.getTargeting
        if (action !== 'POP') {
          clearTargeting()
        } else {
          // TODO: How to handle the case when this page really has no targeting?
          //    Example: click browser back button to homepage.
          //             the targeting from previous page still set.
        }

        if (window.PageOS && window.PageOS.clearAllTags) {
          window.PageOS.clearAllTags()
        }
      }
    })

    return () => {
      // Unload the listener to avoid multiple calls from another page
      unlisten()
      isPreviousPageCategoryPage = isCategoryPage
    }
  }, [history, location.pathname])

  return null
}

HistoryChangeHandler.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(HistoryChangeHandler)
