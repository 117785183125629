import { createGlobalStyle } from 'styled-components/macro'
import defaultGlobalStyle from 'wsc/defaultGlobalStyle'
import { COLORS, FONT_FAMILIES, MEDIA } from '../../utils/styles'
import { FONT_FACE_AVENIR } from '../../configs/fonts'

const GlobalStyle = createGlobalStyle`
  ${defaultGlobalStyle}  
  ${FONT_FACE_AVENIR}
  * {
    box-sizing: border-box;
    word-break: break-word;
    margin: 0;
    padding: 0;
  }
  
  html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  }

  body {
    /** Social Button */
    --socialButton_CircleColor: ${COLORS.TWILIGHT_BLUE};
    --socialButton_CircleHoverColor: ${COLORS.YELLOW};
    --socialButton_CircleActiveColor: ${COLORS.DARK_YELLOW};
    --socialButton_NoBgColor: ${COLORS.WHITE};
    --socialButton_NoBgHoverColor: ${COLORS.YELLOW};
    --socialButton_NoBgActiveColor: ${COLORS.DARK_YELLOW};

    /** inputBoxDefault */
    --inputBoxDefault_borderColor: ${COLORS.LIGHT_GRAY};

    /** MUI */
    --checkbox_Color: ${COLORS.BLUE_TALE};
    --selectedListItem_BgColor: ${COLORS.BLUE_TALE_08};
    --chip_BgColor: ${COLORS.PALE_MAUVE};
    --touchRipple_BgColor: ${COLORS.BLUE_TALE};

    /** defaultPlaceholder */
    --defaultPlaceholder_color: ${COLORS.GREY};
    
    /** withImageBoxShadow*/
    --withImageBoxShadow_boxShadowColor: ${COLORS.PALE_SALMON};

    /** withButton */
    --withButton_BackgroundColor: ${COLORS.TWILIGHT_BLUE};
    --withButton_HoverBackgroundColor: ${COLORS.YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.DARK_YELLOW};

    /** Link */
    --defaultLinkStyle_Color: ${COLORS.BLACK};
    --defaultLinkStyle_BorderBottomColor: ${COLORS.YELLOW};

    /* ContentMarkdown */
    --contentMarkdown_figureCaption_color: ${COLORS.GREY};
    --contentMarkdown_figureContainer_linkBackgroundColor: ${COLORS.TWILIGHT_BLUE};
    --contentMarkdown_figureContainer_linkHoverBefore_opacity: 0.6;
    --contentMarkdown_startQuoteContainer_display: block;
    --contentMarkdown_endQuoteContainer_display: block;
    --contentMarkdown_iconTwitterFillColor: ${COLORS.TWILIGHT_BLUE};
    --contentMarkdown_quoteShareText_borderBottom: 0.125rem solid ${COLORS.YELLOW};
  }

  body, .font-body {
    word-break: break-word;
    font-family: ${FONT_FAMILIES.SANSSERIF};
    color: ${COLORS.BLACK};
    font-size: 1.125rem;
    line-height: 1.7;
    
    ${MEDIA.TABLET`font-size: 1.1875rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  textarea, input[type="text"], input[type="email"] {
    -webkit-appearance: none; /* Remove inner shadow from inputs on mobile iOS */
  }

  h1, h2, h3, h4, .font-h1, .font-h2, .font-h3, .font-h4 {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    color: ${COLORS.BLACK};
  }

  h1, .font-h1 {
    font-weight: bold;
    font-size: 1.60rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 2.32rem;`}
    ${MEDIA.DESKTOP`font-size: 2.96rem;`}
  }

  h2, .font-h2 {
    font-weight: 600; /* semi-bold */
    font-size: 1.42rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.855rem;`}
    ${MEDIA.DESKTOP`font-size: 2.22rem;`}
  }

  h3, .font-h3 {
    font-weight: 600;
    font-size: 1.265rem;
    line-height: 1.6;

    ${MEDIA.TABLET`font-size: 1.48rem;`}
    ${MEDIA.DESKTOP`font-size: 1.666rem;`}
  }

  h4, .font-h4 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.187rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  blockquote {
    font-family: ${FONT_FAMILIES.SERIF};
    font-size: 1.856rem;
    font-style: italic;
    color: ${COLORS.BLACK};
    line-height: 1.33;

    ${MEDIA.MOBILE`font-size: 1.424rem;`}
  }

  hr {
    border: none;
    height: 0.0625rem;
    background-color: ${COLORS.VERY_LIGHT_GREY};
  }

  .font-small-body {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    font-size: 1rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 0.95rem`}
    ${MEDIA.DESKTOP`font-size: 0.9375rem`}
  }

  .font-description {
    /* font size and line-height style based on 'Description' style in style guide */
    font-family: ${FONT_FAMILIES.SANSSERIF};
    font-size: 0.79rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 0.76rem;`}
    ${MEDIA.DESKTOP`font-size: 0.704rem;`}
  }

  .font-section-header-1 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.42rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.856rem;`}
    ${MEDIA.DESKTOP`font-size: 2.22rem;`}
  }

  .font-section-header-2 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.187rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }


  /* TODO: Fix these fonts according to updated style-guide */
  .font-button-title {
    font-size: 1.8em;
  }

  .font-button-subtitle {
    font-weight: bold;
    font-size: 0.86rem;

    ${MEDIA.TABLET`font-size: 0.706rem;`}
    ${MEDIA.DESKTOP`font-size: 0.789rem;`}
  }

  .font-button {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.59;
    font-size: 0.94rem;
  }

  /* -------------------------------------------------------- */
  /* Below are a custom CSS className used for override a global style of all needed components
  /* -------------------------------------------------------- */

  .h4-parenting-title {   
    ${MEDIA.DESKTOP`
    font-size: 1rem;
    line-height: 1.38;
    svg {
      width: 13px; 
      height: 13px;
    }`}
  }

  .suggested-title {   
    ${MEDIA.DESKTOP`font-size: 0.94rem;`}
    ${MEDIA.TABLET`font-size: 0.95rem;`}
    ${MEDIA.MOBILE`font-size: 1rem;`}
    font-weight: 600;
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.7;
  }

  /* --------------------------------------------------------- */
  /* Overrides for wsc defaults */
  /* --------------------------------------------------------- */

  .HomeTopFeaturedMainSection {
    --withImageBoxShadow_boxShadowColor: ${COLORS.BUBBLEGUM};
  }

  .HomeTopFeaturedSubSection {
    --withImageBoxShadow_boxShadowColor: ${COLORS.TWILIGHT_BLUE};
  }

  .HomeTopics {
    --withImageBoxShadow_boxShadowColor: ${COLORS.TWILIGHT_BLUE};
    --withImageBoxShadow_boxShadowLength: 5px;
    ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 8px;`}
    ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 6px;`}
  }

  .AuthorHeader {
    .AuthorImage {
      --withImageBoxShadow_boxShadowLength: 5px;
      ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 8px;`}
      ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 10px;`}
    }
  }

  .AboutContributer {
    .AuthorImage {
      --withImageBoxShadow_boxShadowLength: 4px;
      ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 4px;`}
      ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 6px;`}
    }
  }

  .SuggestedPosts {
    --withImageBoxShadow_boxShadowColor: ${COLORS.TWILIGHT_BLUE};
  }

  .withButtonOutlined {
    --withButton_Color: ${COLORS.TWILIGHT_BLUE};
    --withButton_Border: 1px solid ${COLORS.TWILIGHT_BLUE};
    --withButton_SvgFill: ${COLORS.TWILIGHT_BLUE};

    --withButton_HoverBorder: 1px solid ${COLORS.TWILIGHT_BLUE};
    --withButton_HoverBackgroundColor: ${COLORS.TWILIGHT_BLUE};

    --withButton_ActiveBorder: 1px solid ${COLORS.BLUE_EXTRA_DARK};
    --withButton_ActiveBackgroundColor: ${COLORS.BLUE_EXTRA_DARK};
  }

  .CategoryButton {
    --withButton_Color: ${COLORS.BLACK};
    --withButton_BackgroundColor: ${COLORS.YELLOW};
    --withButton_HoverBackgroundColor: ${COLORS.TWILIGHT_BLUE};
    --withButton_ActiveBackgroundColor: ${COLORS.TWILIGHT_BLUE};
  }

  .WebinarButton {
    --withButton_Color: ${COLORS.WHITE};
    --withButton_BackgroundColor: ${COLORS.GREYISH_TEAL};
    --withButton_HoverBackgroundColor: ${COLORS.YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.DARK_YELLOW};
  }

  .ValentineButton {
    --withButton_BackgroundColor: ${COLORS.VALENTINE_DARK_PINK};
    --withButton_HoverBackgroundColor: ${COLORS.VALENTINE_PINK};
    --withButton_ActiveBackgroundColor: ${COLORS.VALENTINE_PURPLE};
    --withButton_Color: ${COLORS.WHITE};
    --withButton_HoverColor: ${COLORS.WHITE};
    --withButton_ActiveColor: ${COLORS.WHITE};
  }

  .MothersDayButton {
    --withButton_BackgroundColor: ${COLORS.MOTHERS_DAY_DARK_ORANGE};
    --withButton_HoverBackgroundColor: ${COLORS.MOTHERS_DAY_YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.MOTHERS_DAY_ORANGE};
  }

  .FathersDayButton {
    --withButton_BackgroundColor: ${COLORS.FATHERS_DAY_BLUE};
    --withButton_HoverBackgroundColor: ${COLORS.FATHERS_DAY_YELLOW};
    --withButton_ActiveBackgroundColor: ${COLORS.FATHERS_DAY_ORANGE};
  }

  .FirstDayOfSchoolButton {
    --withButton_BackgroundColor: ${COLORS.SCHOOL_DAY_PINK};
    --withButton_HoverBackgroundColor: ${COLORS.SCHOOL_DAY_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.SCHOOL_DAY_GREEN};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
    --withButton_Border: 1px solid ${COLORS.BLACK};
    --withButton_HoverBorder: 1px solid ${COLORS.BLACK};
    --withButton_ActiveBorder: 1px solid ${COLORS.BLACK};
  }

  .HalloweenButton {
    --withButton_BackgroundColor: ${COLORS.HALLOWEEN_GREEN};
    --withButton_HoverBackgroundColor: ${COLORS.HALLOWEEN_SOFT_GREEN};
    --withButton_ActiveBackgroundColor: ${COLORS.HALLOWEEN_PINK};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
    --withButton_Border: 1px solid ${COLORS.BLACK};
    --withButton_HoverBorder: 1px solid ${COLORS.BLACK};
    --withButton_ActiveBorder: 1px solid ${COLORS.BLACK};
  }

  .ThanksgivingButton {
    --withButton_BackgroundColor: ${COLORS.THANKSGIVING_ORANGE};
    --withButton_HoverBackgroundColor: ${COLORS.THANKSGIVING_DARK_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.THANKSGIVING_SOFT_ORANGE};
    --withButton_Color: ${COLORS.BLACK};
    --withButton_HoverColor: ${COLORS.BLACK};
    --withButton_ActiveColor: ${COLORS.BLACK};
  }

  .PopupButton {
    --withButton_BackgroundColor: ${COLORS.BLUE_TALE};
    --withButton_HoverBackgroundColor: ${COLORS.BLUE};
    --withButton_ActiveBackgroundColor: ${COLORS.TWILIGHT_BLUE};
  }

  .HolidayButton {
    --withButton_BackgroundColor: ${COLORS.HOLIDAY_DARK_ORANGE};
    --withButton_HoverBackgroundColor: ${COLORS.HOLIDAY_ORANGE};
    --withButton_ActiveBackgroundColor: ${COLORS.HOLIDAY_ORANGE};
    --withButton_Color: ${COLORS.WHITE};
    --withButton_HoverColor: ${COLORS.WHITE};
    --withButton_ActiveColor: ${COLORS.WHITE};
  }
  
  /** Button Action */
  .buttonAction {
    --buttonAction_Height: 40px;
    --buttonAction_FontFamily: ${FONT_FAMILIES.SANSSERIF};
  }
`

export default GlobalStyle
