import PropTypes from 'prop-types'

const shape = {}

const defaultObj = {}

export default {
  shape,
  defaultObj,
}
