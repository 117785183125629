import avenirBold from '../statics/fonts/AvenirNextLTPro-Bold.otf'
import avenirBoldItalic from '../statics/fonts/AvenirNextLTPro-BoldIt.otf'
import avenirSemiBold from '../statics/fonts/AvenirNextLTPro-Demi.otf'
import avenirSemiBoldItalic from '../statics/fonts/AvenirNextLTPro-DemiIt.otf'
import avenirBlack from '../statics/fonts/AvenirNextLTPro-Heavy.otf'
import avenirBlackItalic from '../statics/fonts/AvenirNextLTPro-HeavyIt.otf'
import avenirItalic from '../statics/fonts/AvenirNextLTPro-It.otf'
import avenir from '../statics/fonts/AvenirNextLTPro-Regular.otf'
import { createFontStyles } from 'wsc/utils/fontFace'

export const AVENIR_FONT_CONFIG = [
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirBold,
    format: 'opentype',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirBoldItalic,
    format: 'opentype',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirSemiBold,
    format: 'opentype',
    fontWeight: '600' /* semi-bold */,
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirSemiBoldItalic,
    format: 'opentype',
    fontWeight: '600' /* semi-bold */,
    fontStyle: 'italic',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirBlack,
    format: 'opentype',
    fontWeight: '900' /* black */,
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirBlackItalic,
    format: 'opentype',
    fontWeight: '900' /* black */,
    fontStyle: 'italic',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenir,
    format: 'opentype',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Avenir Next LT Pro',
    url: avenirItalic,
    format: 'opentype',
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
]

export const FONT_FACE_AVENIR = createFontStyles(AVENIR_FONT_CONFIG)
